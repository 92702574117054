import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseEndpoint: 'https://devapi.exportdm.net/',
  baseURL: 'https://devapi.exportdm.net/',
  // baseEndpoint: 'http://localhost:8000/',
  // baseURL: 'http://localhost:8000/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'},
  headers:{'Access-Control-Allow-Origin': '*'}
})

Vue.prototype.$http = axiosIns

export default axiosIns
